import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PetitionPagePetitionInfoQuery, PetitionPagePetitionInfoQueryVariables } from './petition.graphql';

export type PartialPetition = NonNullable<PetitionPagePetitionInfoQuery['petition']>;

export async function getPetitionInfo(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition | undefined> {
	const { data } = await fetch<PetitionPagePetitionInfoQuery, PetitionPagePetitionInfoQueryVariables>({
		query: gql`
			query PetitionPagePetitionInfo($slugOrId: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					ask
					shouldHideUserActions
					emergencyBannerContent
				}
			}
		`,
		variables: { slugOrId },
		rejectOnError: true,
	});
	return data?.petition || undefined;
}
