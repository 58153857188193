import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

import type { PetitionInfo, Tab, UserInfo } from 'src/app/pages/petition/shared/types';

export type TrackingTabName = 'dashboard' | 'show' | 'edit' | 'comments' | 'discover';

export type TabIsHiddenContext = { petitionInfo: PetitionInfo; userInfo: UserInfo };

export type TabConfig = Readonly<{
	tab: Tab;
	name: TranslationKey;
	isHidden?: (context: TabIsHiddenContext) => boolean;
	buildUrl: (slug: string) => string;
	clickTrackingData: Readonly<{
		tab: TrackingTabName;
	}>;
}>;

export const TABS: readonly TabConfig[] = [
	{
		tab: 'dashboard',
		isHidden: ({ userInfo: { canEdit } }) => !canEdit,
		name: translationKey('fe.pages.petition_manage.nav_bar.dashboard'),
		buildUrl: (slug) => `/p/${encodeURIComponent(slug)}/dashboard`,
		clickTrackingData: {
			tab: 'dashboard',
		},
	},
	{
		tab: 'campaign',
		name: translationKey('fe.components.petition_nav_header.tab.petition'),
		buildUrl: (slug) => `/p/${encodeURIComponent(slug)}`,
		clickTrackingData: {
			tab: 'show',
		},
	},
	{
		tab: 'edit',
		isHidden: ({ userInfo: { canEdit } }) => !canEdit,
		name: translationKey('fe.pages.petition_manage.nav_bar.edit'),
		buildUrl: (slug) => `/p/${encodeURIComponent(slug)}/edit`,
		clickTrackingData: {
			tab: 'edit',
		},
	},
	{
		tab: 'comments',
		isHidden: ({ petitionInfo: { hideComments } }) => hideComments,
		name: translationKey('fe.components.petition_nav_header.tab.comments'),
		buildUrl: (slug) => `/p/${encodeURIComponent(slug)}/c`,
		clickTrackingData: {
			tab: 'comments',
		},
	},
	{
		tab: 'discover',
		// discover is not available to non-signer or starters/staff
		isHidden: ({ userInfo: { signed, canEdit } }) => !signed || canEdit,
		name: translationKey('fe.components.petition_nav_header.tab.discover'),
		buildUrl: () => '/browse?source_location=petition_nav',
		clickTrackingData: {
			tab: 'discover',
		},
	},
];
