import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PetitionPageUserInfoQuery, PetitionPageUserInfoQueryVariables } from './userPetitionInfo.graphql';

type PartialPetition = NonNullable<PetitionPageUserInfoQuery['petition']>;

export async function getUserPetitionInfo(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition> {
	const { data } = await fetch<PetitionPageUserInfoQuery, PetitionPageUserInfoQueryVariables>({
		query: gql`
			query PetitionPageUserInfo($slugOrId: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					viewerCanEdit
					user {
						id
					}
				}
			}
		`,
		variables: { slugOrId },
	});
	if (!data?.petition) {
		throw new Error('failed to retrieve petition info');
	}
	return data?.petition;
}
