import type { JSX, PropsWithChildren } from 'react';

import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
	active?: boolean;
	href: string;
	badge?: JSX.Element | null;
};

const LINK_OUTLINE_WIDTH = 2;
const LINK_OUTLINE_OFFSET = 1;
const LINK_MARGIN = LINK_OUTLINE_WIDTH + LINK_OUTLINE_OFFSET;
const BOTTOM_RULE_WIDTH = 2;
const MARGIN = 10;

export function Tab({ active, href, onClick, children, badge, ...rest }: PropsWithChildren<Props>): JSX.Element | null {
	return (
		// using onMouseDown otherwise click is only triggered the first time a tab is clicked (for some reason...)
		<RouterLink
			to={href}
			sx={{
				...linkResetStyles,
				display: 'inline-block',
				px: MARGIN,
				my: LINK_MARGIN,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					color: 'primary-changeRed',
				},
			}}
			onMouseDown={onClick}
			{...rest}
		>
			<Box
				sx={{
					position: 'relative',
				}}
			>
				<Flex sx={{ alignItems: 'center' }}>
					<Text
						size="default"
						sx={{ lineHeight: `${46 - LINK_MARGIN}px`, color: active ? 'primary-changeRed' : 'inherit' }}
					>
						{children}
					</Text>
					{badge || null}
				</Flex>
				<Separator
					role="presentation"
					sx={{
						position: 'absolute',
						bottom: -1 * BOTTOM_RULE_WIDTH - LINK_MARGIN,
						left: -1 * MARGIN,
						width: `calc(100% + ${MARGIN * 2}px)`,
						borderBottomWidth: 2,
					}}
					m={0}
				/>
				<Separator
					role="presentation"
					sx={{
						position: 'absolute',
						bottom: -1 * BOTTOM_RULE_WIDTH - LINK_MARGIN,
						left: 0,
						width: '100%',
						borderBottomColor: active ? 'primary-changeRed' : 'transparent',
						borderBottomWidth: 2,
					}}
					m={0}
				/>
			</Box>
		</RouterLink>
	);
}
