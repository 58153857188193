import type { JSX } from 'react';

import type { PetitionInfo, Tab, UserInfo } from 'src/app/pages/petition/shared/types';

import { PetitionTabsContainer } from './Tabs';

type Props = {
	slug: string;
	activeTab?: Tab;
	petitionInfo: PetitionInfo;
	userInfo: UserInfo;
};

export function NavigationContainer({ slug, activeTab, petitionInfo, userInfo }: Props): JSX.Element | null {
	return <PetitionTabsContainer activeTab={activeTab} userInfo={userInfo} petitionInfo={petitionInfo} slug={slug} />;
}
