import type { PrefetchedQueryState } from '@change-corgi/core/react/async';
import { prefetchQuery, usePrefetchableQuery } from '@change-corgi/core/react/async';
import { createPrefetchState } from '@change-corgi/core/react/prefetch';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { Session } from '@change-corgi/core/session';

import type { UserInfo, UserInfoState } from 'src/app/pages/petition/shared/types';
import { getInitialPrefetchUserDataQueryId, usePrefetchUserDataQueryId } from 'src/app/shared/hooks/query';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { getUserInfo } from '../api/userInfo';

export type SignerState = PrefetchedQueryState<{ info: UserInfo }>;

export function useUserInfo(slug: string, prefetchedUserInfo?: UserInfo): UserInfoState {
	const utilityContext = useUtilityContext();
	const sessionState = useSessionAsync();
	const session = isLoaded(sessionState) ? sessionState.value : undefined;
	const queryId = `${slug}:${usePrefetchUserDataQueryId()}`;

	return usePrefetchableQuery(
		async () => ({ info: await getUserInfo(session, slug, utilityContext) }),
		[session, slug, utilityContext],
		{
			id: queryId,
			prefetchedState:
				prefetchedUserInfo === undefined
					? undefined
					: createPrefetchState(queryId, 'loaded', { info: prefetchedUserInfo }),
			errorHandler: (e: Error) => ({ cause: e }),
		},
	);
}

export const prefetchUserInfo = async (
	session: Session | undefined,
	slug: string,
	utilityContext: UtilityContext,
): Promise<UserInfo | undefined> => {
	const state = await prefetchQuery(async () => ({ info: await getUserInfo(session, slug, utilityContext) }), {
		id: `${slug}:${getInitialPrefetchUserDataQueryId()}`,
		errorHandler: (e: Error) => ({ cause: e }),
	});
	return isLoaded(state) ? state.info : undefined;
};
